<template>
  <!-- 拣货任务 -->
  <div class="app-container">
    <el-form ref="inboundForm" :model="inboundForm" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="Shipment ID">
              <el-input v-model="inboundForm.shipmentId" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库单号">
              <el-input v-model="inboundForm.outboundCode" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库仓库">
              <el-select v-model="inboundForm.outboundWarehouseCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的仓库">
              <el-select v-model="inboundForm.destinationWarehouseCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">
              <el-select v-model="inboundForm.shipmentStatus" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option v-for="item in getDictionariesOpt('VWMS_SHIPMENT_STATUS')" :key="item.val" :value="item.val" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="出库时间">
              <div style="display:flex">
                <el-select v-model="inboundForm.tableSuffix" :placeholder="$t('page.selectPlaceholder')" style="margin-right: 20px;">
                  <el-option v-for="item in getDictionariesOpt('VWMS_OUTBOUND_YEAR')" :key="item.val" :value="item.val" :label="item.label" />
                </el-select>
                <el-date-picker
                  v-model="inboundForm.transportDate"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  :start-placeholder="$t('title.starttime')"
                  :end-placeholder="$t('title.endtime')"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="getShipmentList()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="shipmentId" label="Shipment ID" align="center">
        <template slot-scope="scope">
          <router-link :to="{ append:true, path: 'details', query: { row:JSON.stringify(scope.row) }}">
            <!-- <el-button type="text">{{ scope.row.shipmentId }}</el-button> -->
            <el-link :underline="false" type="primary">{{ scope.row.shipmentId }}</el-link>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="outboundCode" label="出库单号" align="center" />
      <el-table-column prop="outboundWarehouseName" label="出库仓库" align="center">
        <!-- <template slot-scope="scope">{{ scope.row.outboundWarehouseName }}</template> -->
      </el-table-column>
      <el-table-column prop="destinationWarehouseName" label="目的仓库" align="center" />
      <el-table-column prop="outboundTime" label="出库时间" align="center" />
      <el-table-column prop="expectPairs" label="应出库数量" align="center" />
      <el-table-column prop="actualPairs" label="实出库数量" align="center" />
      <el-table-column prop="actualWeight" label="实出库重量（KG）" align="center" />
      <el-table-column prop="actualVolume" label="实出库体积（CBM）" align="center" />
      <el-table-column prop="shipmentStatus" label="状态" align="center">
        <template slot-scope="scope">{{ getShipmentStatus(scope.row.shipmentStatus) }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 下载港口标 -->
          <span v-if="scope.row.shipmentStatus == 1&&scope.row.destinationWarehouseName.indexOf('FBA')!==-1">
            <el-popover
              :ref="`popover-${scope.$index}`"
              placement="left"
              width="300"
              @hide="hidePopover(scope.$index)"
            >
              <div style="display: flex;margin-bottom: 10px;">
                <span style="align-self: center;margin-right: 5px;">标签数量&emsp;:&emsp;</span>
                <el-input-number v-model="labelsNum" style="width:200px" :min="0" />
              </div>
              <div style="display: flex;margin-bottom: 10px;">
                <span style="align-self: center;margin-right: 5px;">打印类型&emsp;:&emsp;</span>
                <el-select v-model="paperType" :placeholder="$t('title.printtypes')" style="width:200px;">
                  <el-option :label="$t('title.page1')" value="PackageLabel_Plain_Paper" />
                  <el-option :label="$t('title.page2')" value="PackageLabel_A4_2" />
                  <el-option :label="$t('title.page4')" value="PackageLabel_A4_4" />
                  <el-option :label="$t('title.page6')" value="PackageLabel_Letter_6" />
                </el-select>
              </div>

              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleClose(scope.$index)">取消</el-button>
                <el-button type="primary" size="mini" @click="downLabel(scope.row)">下载</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">下载港口标</el-button>
            </el-popover>

          </span>
          <!-- <el-button type="text" size="small" @click="handleRelease(scope.row)"> -->
          <!-- 查看 -->
          <span class="ml-1">
            <router-link :to="{ append:true, path: 'details', query: { row:JSON.stringify(scope.row) }}">
              <el-button type="text" size="small">查看</el-button>
            </router-link>
          </span>
          <!-- </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />
  </div>
</template>
<script>
import { getPageShipment, downPortMark } from '@/api/stock-out'
import Paging from '@/components/Paging'
import { Mixin } from '@/mixin/mixin.js'
import { queryWarehouseList } from '@/api/stockin'

export default {
  name: 'ThipmentManage',
  components: { Paging },
  mixins: [Mixin],
  data() {
    return {
      labelsNum: 0,
      paperType: 'PackageLabel_Plain_Paper',
      inboundForm: {
        shipmentId: '',
        tableSuffix: new Date().getFullYear(),
        transportDate: []
      },
      showRow: false, // 切换显示input
      tableLoading: false,
      tableDatas: [],
      warehouseList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      }

    }
  },
  computed: {
    queryParams() {
      const { transportDate = [] } = this.inboundForm
      const [outboundTimeStart, outboundTimeEnd] = transportDate || []
      return Object.assign({}, this.pager, this.inboundForm, { outboundTimeStart, outboundTimeEnd, transportDate: [] })
    }
  },

  mounted() {
    this.getShipmentList()
    this._queryWarehouseList()
  },
  methods: {
    // 下载港口标
    downLabel(row) {
      const { outboundCode, platformCode, shipmentId, siteCode, shopCode, tableSuffix } = row
      const { paperType, labelsNum } = this
      if (!labelsNum) {
        this.$message({
          message: '请输入标签数量',
          type: 'warning'
        })
        return
      }
      this._downPortMark({
        numberOfPackages: labelsNum, // 打印数量
        outboundCode,
        pageType: paperType, // 纸张类型
        sendSiteCode: siteCode,
        shipmentId,
        platformCode,
        shopCode,
        tableSuffix
      })
    },
    async _downPortMark(params) {
      const { datas } = await downPortMark(params)
      if (datas) window.open(datas)
    },
    hidePopover(index) {
      this.labelsNum = 0
      this.paperType = 'PackageLabel_Plain_Paper'
    },
    handleClose(index) {
      this.$refs[`popover-${index}`].doClose()
    },
    queryClick() { // 点击查询获取信息
      this.pager.current = 1
      this._queryTransport()
    },
    resetClick(val) { // 点击重置清空文本框信息
      this.inboundForm = {
        shipmentId: '',
        tableSuffix: '2022',
        transportDate: []
      }
      this.getShipmentList(1)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async getShipmentList(flag) {
      try {
        this.loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getPageShipment(this.queryParams)
        this.pager = pager
        this.tableDatas = records
      } finally {
        this.loading = false
      }
    },
    getShipmentStatus(status) {
      switch (status + '') {
        case '1':
          return '待发运'
        case '2':
          return '已发运'
        case '3':
          return '缺量发运'
        case '4':
          return '终止发运'
        default:
          return ''
      }
    },
    // 释放任务
    handleRelease(row) {

    },
    update(val) {
      this.pager = val
      this.getShipmentList()
    },
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseList = datas
    }
  }
}
</script>

